import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import Carousel from "../shared/carousel"
import { Container, Row, Col } from "../shared/grid"
import { H2, H3, P, Span } from "../shared/text"
import Button from "../shared/button"

import QuoteIMG from "../../images/quotes.svg"
import QuoteWhiteIMG from "../../images/quote-white.svg"

const Styles = {

  Wrapper: styled.div`
    margin-top: 10px;
    ${props => props.theme === 'afr' ? `` : `background-color:#0E162D;`}
    padding-top:40px;
    padding-bottom:40px;

    @media (min-width: 768px) {
      margin-top: 10px;
      padding-top:60px;
      padding-bottom:60px;
    }

    @media (min-width: 1038px) {
      margin-top: 10px;
      padding-top:80px;
      padding-bottom:80px;
    }
  `,

  TextWrapper: styled.div`
    text-align: center;
    margin-top: 30px;

    button {
      ${props => props.theme === 'afr' ? `padding-left: 0px;` : ``}
    }

    @media (min-width: 768px) {
      text-align: center;
      margin: 0px 0px 20px 0px;
    }

    @media (min-width: 1038px) {
      text-align: left;
      padding: 0px 30px;
      margin: 0px 0px;
      position: relative;

      &::before {
        position: absolute;
        left: 0px;
        top: 10px;
        content: " ";
        width: 2px;
        height: 70px;
        ${props => props.theme === 'afr' ? ` background-color: #0f6cc9;` : `background-color:#D8162E;`}
      }
    }
  `,

  TestimonialWrapper: styled.div`
    padding: 40px 30px;
    ${props => props.theme === 'afr' ? `background-image: url(${QuoteIMG});` : `background-image: url(${QuoteWhiteIMG});`}
    background-repeat: no-repeat;
    background-position: top right;
    text-align: center;
    position: relative;
    height:280px;
    margin-top:30px;

    ${props => props.theme === 'afr' ? 
      `border: 1px solid #c5c5c5;` : 
      `border: 1px solid #c5c5c5;
        border-radius:8px;
      `
    }

    p {
      font-size: 16px;
      line-height: 28px;
      ${props => props.theme === 'afr' ? ` color: #555555;` : `color: white;`}
    }

    @media (min-width: 768px) {
      margin-top:0px;
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }

    @media (min-width: 1038px) {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  `,

  TestimonialBottom: styled.div`
    bottom:30px;
    left:0px;
    width:100%;
    text-align:center;
    line-height:10px;
    position: absolute;

    h3{
        margin-bottom:0px;
        ${props => props.theme === 'afr' ? `` : `color: white;`}
    }

    span{
        margin-bottom:0px;
        ${props => props.theme === 'afr' ? `` : `color: white;`}
    }
  `,

}

const Testimonial = ({ theme, quote, name, title, company }) => (
  <Styles.TestimonialWrapper theme={theme}>
    <P>{quote}</P>
    <Styles.TestimonialBottom theme={theme}>
        <H3>{name}</H3>
        <Span>{title},</Span><br/>
        <Span>{company}</Span>
    </Styles.TestimonialBottom>
  </Styles.TestimonialWrapper>
)

const breakpoints = {
    280: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        centeredSlides: false,
    },
    728: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
    },
}

const TestimonialsBlock = ({ data }) => {

  const block = data.testimonials.blocks[0].block

  return(
    <Styles.Wrapper theme={process.env.GATSBY_THEME}>
      <Container>
        <Row>
          <Col md={8} lg={4} mdOffset={2} lgOffset={'0'}>
            <Styles.TextWrapper theme={process.env.GATSBY_THEME}>
              <H2 color={(process.env.GATSBY_THEME === 'afr' ? 'black' : 'white')}>{block.title}</H2>
              <P color={(process.env.GATSBY_THEME === 'afr' ? 'black' : 'white')}>{block.description.text}</P>
              <div onClick={() => scrollTo('#form')}><Button style={(process.env.GATSBY_THEME === "afr" ? "inline" : "blue")} label={block.ctaText} /></div>
            </Styles.TextWrapper>
          </Col>
          <Col md={12} lg={8}>
              <Carousel breakpoints={breakpoints} hash="testimonials" loop={true} autoplay={(block.references.length > 2 ? { delay: 5000 } : false)} pagination={false}>
                {
                  block.references.map((testimonial, index) => {
                    return(
                      <Testimonial
                        theme={process.env.GATSBY_THEME}
                        key={index}
                        quote={testimonial.quote.text}
                        name={testimonial.name}
                        title={testimonial.title}
                        company={testimonial.company}
                      />
                    )
                  })
                }
              </Carousel>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  )
}

const query = graphql`
{
  testimonials:allContentfulBlock(limit:1, filter:{slug:{eq:"testimonials"}}){
    blocks:edges{
      block:node{
        slug
        title
        subtitle
        description{
          text:description
        }
        images{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
        ctaText
        references{
          ... on ContentfulTestimonial{
            name
            quote{
              text:quote
            }
            title
            company
          }
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <TestimonialsBlock data={data} {...props} />}
  />
)