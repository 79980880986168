import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Helmet from "react-helmet"

const MetaBlock = ({ data }) => {

    const { title, description } = data.meta.edges[0].node

    return(
        <Helmet>
            {title && <title>{title}</title>}
            {title && <meta name="title" content={title} />}
            {description && <meta name="description" content={description}></meta>}
        </Helmet>
    )
}


const query = graphql`
{
    meta:allContentfulMeta(limit:1){
      edges{
        node{
          title
          description
        }
      }
    }
  }
`

export default props => (
    <StaticQuery
      query={query}
      render={data => <MetaBlock data={data} {...props} />}
    />
  )