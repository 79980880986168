import React, { useState } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { gsap } from "gsap"

import { Container, Row, Col, Center } from "../shared/grid"
import { H2 } from "../shared/text"

import BlueArrowSVG from "../../images/blue-arrow.svg"
import RedArrowSVG from "../../images/red-arrow.svg"

const Styles = {

    Wrapper: styled.div`
        
        padding-top:60px;

        ${props => props.theme === 'afr' ? 
            `
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(241,246,252,1) 100%);
            ` : 
            `
            background: white;
            `
        }

        @media (min-width: 768px) {

            padding-top:80px;
        }

        @media (min-width: 1038px) {
            padding-top:120px;
        }
    `,

    ListContainer: styled.div`
        background-color:white;
        min-height:100px;
        padding:22px;
        text-align:center;
        margin-bottom:20px;

        ${props => props.theme === 'afr' ?
         `border:1px solid #0F6CC9;`: 
         `border:1px solid #d3d3d3;
          border-radius:8px;
         `
        }
    `,

    BlueTitle: styled.h4`
        
        ${process.env.GATSBY_THEME === 'afr' ? 
            `font-family: sueca_hd_regular;`
            :
            `
            font-family: abril-titling, serif;
            font-weight: 700;
            font-style: normal;
            ` 
        }
        
        font-size:21px;
        line-height:32px;
        margin-top:20px;
        ${props => props.theme === 'afr' ?
         `color:#0F6CC9;`: 
         `color:black;`
        }
    `,

    FAQContainer: styled.div`
        border-bottom:1px solid #C2D1D9;
        ${props => props.theme === 'afr' ?
         `border-bottom:1px solid #C2D1D9;`: 
         `border-bottom:1px solid #d3d3d3;`
        }
        padding-top:20px;
        padding-bottom:10px;
        text-align:left;
        position: relative;
        cursor:pointer;

        &:last-child{   
            border-bottom:none;
        }
    `,

    FAQTitle: styled.h5`
        color:black;
        font-size:16px;
        line-height:24px;
        font-weight:bold;
        width:95%;
        margin:0;
        display:block;
        position:relative;
    `,

    FAQCopy: styled.p`
        color:#555555;
        font-size:14px;
        line-height:22px;
        height:0px;
        overflow-y:hidden;
        margin:0;
        display:block;
        position:relative;
        padding-top:10px;
        margin-bottom:10px;

        a{
            color:#0F6CC9;
        }
    `,

    Arrow: styled.img`
        position:absolute;
        top:30px;
        right:0;
        ${props => props.flip === true ? `transform: scaleY(-1);` : `transform: scaleY(1);`}
    `,
}   

const FAQBlock = ({ data }) => {

    const block = data.faqs.blocks[0].block
    const groups = block.references

    return (
        <Styles.Wrapper id="faqs" theme={process.env.GATSBY_THEME}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Center>
                            <H2>Frequently Asked Questions</H2>
                        </Center>
                    </Col>
                </Row>
                <Row>
                {groups.map((group, index) => {
                    return (
                        <Col md={6} key={index}>
                            <Styles.ListContainer theme={process.env.GATSBY_THEME}>
                                <Styles.BlueTitle theme={process.env.GATSBY_THEME}>{group.title}</Styles.BlueTitle>
                                {group.faQs.map((faq, i) => {
                                    return(
                                        <FAQTile 
                                            title={faq.question}
                                            description={faq.answer.answer} 
                                            index={`${index}-${i}`}
                                            key={i}
                                            theme={process.env.GATSBY_THEME}
                                        />
                                    )
                                })}
                            </Styles.ListContainer>
                        </Col>
                    )
                })}
                </Row>
            </Container>
        </Styles.Wrapper>
    )
}

const FAQTile = ({ title, description, index, theme }) => {

    const [ open, setOpen ] = useState(false)

    const toggle = () => {

        if(open){
            gsap.to(`#faq-tile-${index}`, .2, { css:{ height:0 } })
            setOpen(false)
        }
        else{
            gsap.to(`#faq-tile-${index}`, .2, { css: { height:'auto'} })
            setOpen(true)
        }

    }

    return(
        <Styles.FAQContainer onClick={toggle}>
            <Styles.Arrow flip={open} src={(theme === 'afr' ? BlueArrowSVG : RedArrowSVG)} />
            <Styles.FAQTitle>{title}</Styles.FAQTitle>
            <Styles.FAQCopy id={`faq-tile-${index}`} dangerouslySetInnerHTML={{ __html: description }} />
        </Styles.FAQContainer>
    )
}

const query = graphql`
{
    faqs: allContentfulBlock(limit: 1, filter: { slug: { eq: "faqs" } }) {
        blocks: edges{
        block: node{
          references{
            ... on ContentfulFaqGroup{
              title
              faQs{
                ... on ContentfulFaq{
                  question
                  answer{
                    answer
                  }
                }
              }
            }
          }
        }
      }
    }	
  }
`

export default props => (
    <StaticQuery
      query={query}
      render={data => <FAQBlock data={data} {...props} />}
    />
  )