import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import { Container, Row, Col } from "../shared/grid"
import { H2 } from "../shared/text"
import Button from "../shared/button"
import VideoPlayer from "../shared/video"

import "../../styles/lib/plyr.scss"

const Styles = {

  Wrapper: styled.div`
    padding: 0px 0px;
  `,

  TextWrapper: styled.div`
    text-align: center;
    margin-top: 30px;

    @media (min-width: 768px) {
      text-align: left;
      padding: 0px 0px 0px 30px;
      margin: 0px 30px;
      position: relative;

      &::before {
        position: absolute;
        left: 0px;
        top: 10px;
        content: " ";
        width: 2px;
        height: 70px;
        background-color: #0f6cc9;
      }
    }
    
    @media (min-width: 1038px) {
      text-align: left;
      padding: 0px 30px;
      margin: 0px 30px;
      position: relative;

      &::before {
        position: absolute;
        left: 0px;
        top: 10px;
        content: " ";
        width: 2px;
        height: 70px;
        background-color: #0f6cc9;
      }
    }
  `,

  BrandTile: styled.img`
    width: 100%;
  `,

  Row: styled(Row)`
    margin-top: 60px;
    margin-bottom: 60px;

    h3 {
      padding-top: 32px;
    }
  `,

  Col: styled(Col)`
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  `,
}

const breakpoints = {
  280: {
    slidesPerView: 2.5,
    spaceBetween: 0,
    centeredSlides: false,
  },
  728: {
    slidesPerView: 3,
    spaceBetween: 0,
    centeredSlides: false,
  },
  980: {
    slidesPerView: 3,
    spaceBetween: 0,
    centeredSlides: false,
  },
}

const VideoBlock = ({ data }) => {
  
  const video = data.video.blocks[0].block

  return (
    <Styles.Wrapper>
      <Container>
        <Row>
          <Col md={7}>
            <VideoPlayer
              url={video.video.file.url}
              poster={video.images[0].file.url}
            />
          </Col>
          <Col md={5}>
            <Styles.TextWrapper>
              <H2>{video.title}</H2>
              <div onClick={() => scrollTo('#form')}><Button style="inline" label={video.ctaText} /></div>
            </Styles.TextWrapper>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  )
}

const query = graphql`
{
  video:allContentfulBlock(limit:1, filter:{slug:{eq:"video"}}){
    blocks:edges{
      block:node{
        slug
        title
        subtitle
        description{
          text:description
        }
        images{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
        ctaText
        references{
          ... on ContentfulTestimonial{
            name
            quote{
              text:quote
            }
            title
            company
          }
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <VideoBlock data={data} {...props} />}
  />
)