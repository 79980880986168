import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

import { Container, Row, Col } from "../shared/grid"
import { H3 } from "../shared/text"
import Carousel from "../shared/carousel"

const Styles = {

  Wrapper: styled.div`
    padding: 0px 0px;
  `,

  TextWrapper: styled.div`
    text-align: center;
    margin-top: 30px;

    @media (min-width: 768px) {
      text-align: left;
      padding: 0px 0px 0px 30px;
      margin: 0px 30px;
      position: relative;

      &::before {
        position: absolute;
        left: 0px;
        top: 10px;
        content: " ";
        width: 2px;
        height: 70px;
        background-color: #0f6cc9;
      }
    }
    
    @media (min-width: 1038px) {
      text-align: left;
      padding: 0px 30px;
      margin: 0px 30px;
      position: relative;

      &::before {
        position: absolute;
        left: 0px;
        top: 10px;
        content: " ";
        width: 2px;
        height: 70px;
        background-color: #0f6cc9;
      }
    }
  `,

  BrandTile: styled.img`
    width: 100%;
  `,

  Row: styled(Row)`
    margin-top: 60px;
    margin-bottom: 60px;

    h3 {
      padding-top: 32px;
    }
  `,

  Col: styled(Col)`
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  `,
}

const breakpoints = {
  280: {
    slidesPerView: 2.5,
    spaceBetween: 0,
    centeredSlides: false,
  },
  728: {
    slidesPerView: 3,
    spaceBetween: 0,
    centeredSlides: false,
  },
  980: {
    slidesPerView: 3,
    spaceBetween: 0,
    centeredSlides: false,
  },
}

const BrandBlock = ({ data }) => {

  const brands = data.brands.blocks[0].block

  return(
    <Styles.Wrapper>
      <Container>
        <Styles.Row>
          <Styles.Col md={5}>
            <H3>{brands.title}</H3>
          </Styles.Col>
          <Col md={7}>
            <Carousel
              breakpoints={breakpoints}
              hash="brands"
              loop={true}
              autoplay={{ delay: 2000 }}
              pagination={false}
            >
              {
                brands.images.map((image, index) => {
                  return ( <Styles.BrandTile src={image.file.url} key={index} /> )
                })
              }
            </Carousel>
          </Col>
        </Styles.Row>
      </Container>
    </Styles.Wrapper>
  )
  
}

const query = graphql`
{
  brands:allContentfulBlock(limit:1, filter:{slug:{eq:"brands"}}){
    blocks:edges{
      block:node{
        slug
        title
        subtitle
        description{
          text:description
        }
        images{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
        ctaText
        references{
          ... on ContentfulTestimonial{
            name
            quote{
              text:quote
            }
            title
            company
          }
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <BrandBlock data={data} {...props} />}
  />
)