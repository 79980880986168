import React, { useEffect } from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import LisenceBlock from "../components/blocks/lisence"
import FAQBlock from "../components/blocks/faq"
import HookForm from "../components/blocks/hook-form"
import VideoBlock from "../components/blocks/video"
import BrandBlock from "../components/blocks/brands"
import TestimonialsBlock from "../components/blocks/testimonials"
import MetaBlock from "../components/blocks/meta"

const Styles = {
  BlueWrapper: styled.div`
    position:relative;
    ${props => props.theme === 'afr' ? 
      `` : 
      `background: rgb(255,255,255);
       background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(227,231,235,1) 100%);`
    }
  `,
}

const IndexPage = () => {

  console.log(process.env.GATSBY_MAINTENENCE)

  useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location
      if (hash) {
        const id = hash.replace("#", "")
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "instant" })
          window.scrollBy(0, -55)
        }
      }
    }, 0)
  }, [])

  return(
    <>
      { process.env.GATSBY_MAINTENENCE === "true" && <div /> }
      { process.env.GATSBY_MAINTENENCE === "false" && 
        <Layout>
          <MetaBlock />
          <Styles.BlueWrapper theme={process.env.GATSBY_THEME}>
            <LisenceBlock />
            <HookForm />
          </Styles.BlueWrapper>
          { process.env.GATSBY_THEME === 'afr' && <VideoBlock /> }
          <BrandBlock />
          <TestimonialsBlock />
          <FAQBlock />
        </Layout>
      }
    </>
  )
  
}

export default IndexPage
