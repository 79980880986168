import React, { useState } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import Recaptcha from "react-google-recaptcha"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"

import { Container, Row, Col, Center } from "../shared/grid"
import { H3, P, Span } from "../shared/text"
import Spinner from "../shared/spinner"
import Button from "../shared/button"

import SelectArrowIMG from "../../images/select-down.svg"
import LaptopIMG from "../../images/laptop.png"
import TickIMG from "../../images/tick.svg"
import CrossIMG from "../../images/cross.svg"

const Styles = {
  Wrapper: styled.div`
    margin-top: 0px;
    text-align: center;
    margin-bottom: 100px;
    transform: translateY(40px);
  `,

  Inner: styled.div`
    padding: 0px 10px 50px 10px;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
      background-color: white;
      padding: 50px 50px;
      ${props => props.theme === 'afr' ? `border: 1px solid #4b90d6;` : 
        `
          border-top-left-radius:8px;
          border-top-right-radius:8px;
        `
      }
    }

    @media (min-width: 1038px) {
    }
  `,

  Form: styled.div``,

  Fields: styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      width: 640px;
    }
  `,

  InputWrapper: styled.div`
    width: 100%;
    position: relative;
    @media (min-width: 768px) {
      width: 50%;
    }

    button {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 8px;
      width: calc(100% - 20px);
      margin: 0px;
    }
  `,

  InputWrapperButton: styled.div`
    width: 100%;
    position: relative;
    @media (min-width: 768px) {
      width: 60%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 20px);
      margin: auto;
    }
  `,

  InputWrapperCaptch: styled.div`
    width: 100%;
    position: relative;
    height: 80px;
    /* @media (min-width: 768px) {
      width: 50%;
    } */
  `,

  TextInput: styled.input`
    padding: 16px 12px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    width: calc(100% - 40px);
    border: 1px solid ${props => props.error ? 'red' : '#bbbbbb'};
    background-color: white;
    border-radius: 3px;
    font-size: 14px;
    outline: none;

    &::placeholder {
      color: #bbbbbb;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border: 1px solid #007aff;
    }
  `,

  SelectInput: styled.select`
    padding: 20px 12px 17px 12px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    width: calc(100% - 14px);
    border: 1px solid #bbbbbb;
    background-color: white;
    border-radius: 3px;
    appearance: none;
    outline: none;
    font-size: 10px;
    background-image: url(${SelectArrowIMG});
    background-size: 10px 5px;
    background-position: 95% 22px;
    background-repeat: no-repeat;

    &::placeholder {
      color: #bbbbbb;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border: 1px solid #000;
    }
  `,

  StatusField: styled.div`
    width: 100%;
    padding-top: 12px;
  `,

  Recaptcha: styled.div`
    position: absolute;
    top: -8px;
    left: 22px;
    transform: scale(0.81);

    @media (min-width: 768px) {
      position: absolute;
      top: -6px;
      left: 50%;
      margin-left:-150px;
      transform: scale(0.7);
    }
  `,

  Error: styled.div`
    line-height:20px;
    font-size:12px;
    background-color: #ef5f21;
    color: white;
    padding: 20px 46px;
    display: inline-block;
    border-radius: 4px;
    background-image: url(${CrossIMG});
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: 16px 22px;

    @media (min-width: 768px) {
      line-height:0px;
      font-size:16px;
      background-position: 16px 9px;
    }
  `,

  Success: styled.div`
    line-height:20px;
    font-size:12px;
    background-color: #71bf4d;
    color: white;
    padding: 20px 46px;
    display: inline-block;
    border-radius: 4px;
    background-image: url(${TickIMG});
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: 16px 18px;

    @media (min-width: 768px) {
      line-height:0px;
      font-size:16px;
      background-position: 16px 9px;
    }
  `,

  AFRFooter: styled.div`
    height: 250px;
    background-color: #0f6cc9;
    margin-left: auto;
    margin-right: auto;
    background-image: url(${LaptopIMG});
    background-position: bottom center;
    background-size: 100%;
    background-repeat: no-repeat;
    border-left: 1px solid #4b90d6;
    border-right: 1px solid #4b90d6;
    border-bottom: 1px solid #4b90d6;

    span {
      display: inline-block;
      width: 80%;
      margin-left: 0%;
      margin-top: 20px;

      a {
        color: white;
      }
    }

    @media (min-width: 768px) {
      max-width: 930px;
      height: 110px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -50px 10px;
      text-align: left;

      span {
        width: 65%;
        font-size: 14px;
        margin-left: 34%;
      }
    }

    @media (min-width: 1038px) {
      max-width: 930px;
      height: 80px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -50px 10px;
      text-align: left;

      span {
        width: 75%;
        font-size: 14px;
        margin-left: 22%;
      }
    }
  `,

  SMHAGEFooter: styled.div`
    margin-left: auto;
    margin-right: auto;
    background: rgb(78,34,64);
    background: linear-gradient(90deg, rgba(78,34,64,1) 0%, rgba(217,23,47,1) 100%);
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    padding:24px;
    color:white;
    line-height:22px;

    a{
      color:white;
    }
    h4{
      margin:0;
      margin-bottom:8px;
      padding:0;
      font-size:21px;
      font-family: abril-titling, serif;
      font-weight: 600;
      font-style: normal;
    }

    @media (min-width: 768px) {
      max-width: 882px;
    }
  `,

}

const HookForm = ({ data }) => {

  const block = data.form.blocks[0].block
  const { register, handleSubmit, errors, triggerValidation, formState } = useForm({ mode: 'onChange'})
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ recaptcha, setRecaptcha ] = useState(null)

  const handleRecaptcha = value => {
    setRecaptcha(value)
    triggerValidation()
  }

  const onSubmit = data => {
 
    setLoading(true)
    const url = `/.netlify/functions/register`
    data['g-recaptcha-response'] = recaptcha
    data['Theme'] = process.env.GATSBY_THEME

    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(response => {
      setLoading(false)
      setSuccess(true)

      ReactGA.initialize(process.env.GATSBY_GA_CODE);
      ReactGA.event({
        category: "Contact",
        action: "ContactRequest"
      })

      console.log({
        category: "Contact",
        action: "ContactRequest"
      })

    })
    .catch(err => {
      console.log(err)
      setLoading(false)
      setError(true)
    })
    
  }

  let FOOTER = (
    <Styles.AFRFooter>
      <Span
        color="white"
        dangerouslySetInnerHTML={{ __html: block.description.text }}
      />
    </Styles.AFRFooter>
  )

  if(process.env.GATSBY_THEME !== "afr"){
    FOOTER = (
      <Styles.SMHAGEFooter>
        <span
          color="white"
          dangerouslySetInnerHTML={{ __html: block.description.text }}
        />
      </Styles.SMHAGEFooter>
    )
  }

  return (
    <Styles.Wrapper id="form">
      <Container>
        <Row>
          {success && 
            <img src={`https://www.facebook.com/tr?id=${process.env.GATSBY_FACEBOOK_PIXEL}&amp;ev=PageView`} height="1" width="1" style={{display:"none"}} />
          }
          <Col md={12}>
            <Styles.Inner theme={process.env.GATSBY_THEME}>
              <Center>
                <H3>{block.title}</H3>
                <P>{block.subtitle}</P>
              </Center>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Styles.Fields>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="text"
                      name="Name"
                      ref={register({ required: true, pattern: /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/ })}
                      placeholder="First name"
                      error={errors.Name}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="text"
                      name="Surname"
                      ref={register({ required: true, pattern: /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/ })}
                      placeholder="Last Name"
                      error={errors.Surname}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="text"
                      name="Company"
                      ref={register({ required: true })}
                      placeholder="Company"
                      error={errors.Company}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="text"
                      name="Title"
                      ref={register({ required: true })}
                      placeholder="Job title"
                      error={errors.Title}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="text"
                      name="Phone"
                      ref={register({ required: true, pattern: /^[0-9]*$/ })}
                      placeholder="Phone number"
                      error={errors.Phone}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.SelectInput
                      name="Hear"
                      ref={register({ required: true })}
                      error={errors.Hear}
                    >
                      <option value="" disabled selected>{ process.env.GATSBY_THEME === 'afr' ? `Where did you hear about AFR corporate subscriptions?` : `Where did you hear about us?` }</option>
                      <option value="Event/Conference">Event/Conference</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Google Search">Google Search</option>
                      <option value="Email">Email</option>
                      <option value="Referral">Referral</option>
                      <option value="Newspaper/Print">Newspaper/Print</option>
                      <option value="Other">Other</option>
                    </Styles.SelectInput>
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.TextInput
                      type="email"
                      name="Email"
                      ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                      placeholder="Email address"
                      error={errors.Email}
                    />
                  </Styles.InputWrapper>
                  <Styles.InputWrapper>
                    <Styles.SelectInput
                      name="Industry"
                      ref={register({ required: true })}
                      error={errors.Industry}
                    >
                      <option value="" disabled selected>
                        What industry do you work in?
                      </option>
                      <option value="Accounting, Banking, Finance, Super">Accounting, Banking, Finance, Super</option>
                      <option value="Aged Care">Aged Care</option>
                      <option value="Construction & Engineering">Construction &amp; Engineering</option>
                      <option value="Education">Education</option>
                      <option value="Government">Government</option>
                      <option value="Health">Health</option>
                      <option value="Legal">Legal</option>
                      <option value="Logistics, Manufacturing, Transport">Logistics, Manufacturing, Transport</option>
                      <option value="Media">Media</option>
                      <option value="Mining, Energy & Resources">Mining, Energy &amp; Resources</option>
                      <option value="Not for Profit">Not for Profit</option>
                      <option value="Other">Other</option>
                      <option value="Professional Services">Professional Services</option>
                      <option value="Real Estate Commercial &amp; Residential">Real Estate Commercial &amp; Residential</option>
                      <option value="Retail">Retail</option>
                      <option value="Technology">Technology</option>
                      <option value="Tourism, Hospitality, Sport">Tourism, Hospitality, Sport</option>
                    </Styles.SelectInput>
                  </Styles.InputWrapper>
                  <Styles.InputWrapperCaptch>
                    <Styles.Recaptcha>
                      <Recaptcha
                        sitekey={"6LfaGNgUAAAAAIy-EDkre2qt1eu54iLvYRq-NeQM"}
                        onChange={handleRecaptcha}
                      />
                    </Styles.Recaptcha>
                  </Styles.InputWrapperCaptch>
                  <Styles.InputWrapperButton>
                    <Button type="submit" style="solid" label="submit" disabled={!recaptcha || !formState.isValid } />
                  </Styles.InputWrapperButton>
                  <Styles.StatusField>
                    <Center>
                      { loading && <Spinner color="#4B90D6" /> }
                      { error && ( <Styles.Error> {" "}Your information was not sent. Please try again later.{" "} </Styles.Error> )}
                      { success && ( <Styles.Success> <span>Enquiry sent one of our sales team will be in contact with you shortly.</span> </Styles.Success> )}
                    </Center>
                  </Styles.StatusField>
                </Styles.Fields>
              </form>
            </Styles.Inner>
            {FOOTER}
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  )
}

const query = graphql`
  {
    form: allContentfulBlock(limit: 1, filter: { slug: { eq: "form" } }) {
      blocks: edges {
        block: node {
          slug
          title
          subtitle
          description {
            text: description
          }
          images {
            file {
              url
            }
          }
          video {
            file {
              url
            }
          }
          ctaText
          references {
            ... on ContentfulTestimonial {
              name
              quote {
                text: quote
              }
              title
              company
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <HookForm data={data} {...props} />}
  />
)
