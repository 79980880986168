import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Video = styled.video`
    width:100%;
`

const VideoWrapper = styled.div`
 
`

class VideoPlayer extends React.Component {

    constructor(props){
        super(props)
        this.player = null
    }

    componentDidMount(){
        const scope = this
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            const plyr = require("plyr")
            this.player = new plyr("#player")
            this.player.on('ended', event => {
                scope.props.onEnd()
            })
        }
    }

    stop(){
        this.player.pause()
    }

    render(){
        return (
            <VideoWrapper>
                <Video id="player" poster={this.props.poster} controls>
                    <source src={this.props.url} type="video/mp4" />
                </Video>
            </VideoWrapper>
        )
    }

}

VideoPlayer.propTypes = {
    url: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
}

export default VideoPlayer