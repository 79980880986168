import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import { Container } from "../shared/grid"
import { H3, P, Span } from "../shared/text"
import Button from "../shared/button"

const Styles = {

    Container: styled(Container)`
        padding-left:0px;
        padding-right:0px;

        @media (min-width: 768px){
            height:146px;
        }
    `,

    Wrapper: styled.div`
        background-color:white;
        display:flex;
        flex-direction:column;
        margin-left:16px;
        margin-right:16px;
        transform: translateY(-50px);
        ${props => props.theme === 'afr' ? `border:1px solid #4B90D6;` : ``}
        ${props => props.theme === 'smh' ? `border-radius:8px;` : ``}
        ${props => props.theme === 'age' ? `border-radius:8px;` : ``}

        @media (min-width: 768px){
            flex-direction:row;
            width:calc(100% - 20px);
            margin-left:10px;
            margin-right:10px;
            transform: translateY(-160px);
        }

        @media (min-width: 1038px){
            width:calc(100% - 32px);
            padding-left:8px;
            padding-right:8px;
            margin-left:auto;
            margin-right:auto;
            transform: translateY(-140px);
            -webkit-box-shadow: 0px 17px 57px -6px rgba(0,0,0,0.27);
            -moz-box-shadow: 0px 17px 57px -6px rgba(0,0,0,0.27);
            box-shadow: 0px 17px 57px -6px rgba(0,0,0,0.27);
        }
    `,

    Block: styled.div`
        padding:50px;
        display:flex;
        flex-direction:column;
        text-align:center;

        &:last-child{
            border-top:1px solid #4B90D6;
        }

        @media (min-width: 768px){
            width:50%;
            padding:35px;
            flex-direction:column;
            text-align:center;

            &:last-child{
                ${props => props.theme === 'afr' ? `border-left:1px solid #4B90D6;` : ``}
                ${props => props.theme === 'smh' ? `border-left:1px solid #E0E0E0;` : ``}
                ${props => props.theme === 'age' ? `border-left:1px solid #E0E0E0;` : ``}
                border-top:none;
            }
        }

        @media (min-width: 1038px){
            padding:50px;
            flex-direction:row;
            text-align:left;
        }

    `,

    OR: styled.div`
        width:48px;
        height:48px;
        position:absolute;
        background-color:black;
        left:50%;
        top:50%;
        margin-top:-4px;
        margin-left:-24px;
        color:white;
        display:flex;
        justify-content:center;
        align-items:center;
        border-radius:50%;
        font-size:14px;
        font-weight:bold;   

        @media (min-width: 768px){
            margin-top:-24px;
        }
    `,

    Icon: styled.div`
        width:100%;
        margin:0px 0px 20px 0px;

        @media (min-width: 768px){
            width:70px;
            margin-left:auto;
            margin-right:auto;
        }
        
        @media (min-width: 1038px){
            width:70px;
            margin:0px;
        }
    `,
    
    Copy: styled.div`
        width:100%;

        @media (min-width: 768px){
            width:100%;
            padding-left:0px;
        }

        @media (min-width: 1038px){
            width:calc(100% - 70px);
            padding-left:10px;
        }
    `,

    ButtonWrapper: styled.div`

        @media (min-width: 768px){
            display:none;
        }
    `,

}

const LisenceBlock = ({data}) => {

    const enterprise = data.enterprise.blocks[0].block
    const team = data.team.blocks[0].block

    return (    
        <Styles.Container>
            <Styles.Wrapper theme={process.env.GATSBY_THEME}>
                <Styles.Block theme={process.env.GATSBY_THEME}>
                    <Styles.Icon>
                        <img src={enterprise.images[0].file.url} />
                    </Styles.Icon>
                    <Styles.Copy>
                        <H3>{enterprise.title}</H3>
                        <P color="#111">{enterprise.subtitle}</P>
                        <Span color="#555555">{enterprise.description.text}</Span>
                        <Styles.ButtonWrapper onClick={() => scrollTo('#form')}>
                            <Button style="solid" label="Enquire today" />
                        </Styles.ButtonWrapper>
                    </Styles.Copy>
                </Styles.Block>
                <Styles.OR>OR</Styles.OR>
                <Styles.Block theme={process.env.GATSBY_THEME}>
                    <Styles.Icon>
                        <img src={team.images[0].file.url} />
                    </Styles.Icon>
                    <Styles.Copy>
                        <H3>{team.title}</H3>
                        <P color="#111">{team.subtitle}</P>
                        <Span color="#555555">{team.description.text}</Span>
                        <Styles.ButtonWrapper onClick={() => scrollTo('#form')} >
                            <Button style="solid" label="Enquire today" />
                        </Styles.ButtonWrapper>
                    </Styles.Copy>
                </Styles.Block>
            </Styles.Wrapper>
        </Styles.Container>
    )
}

const query = graphql`
{
    enterprise:allContentfulBlock(limit:1, filter:{slug:{eq:"enterprise-licence"}}){
        blocks:edges{
          block:node{
            title
            subtitle
            description{
              text:description
            }
            images{
              file{
                url
              }
            }
          }
        }
    }
    team:allContentfulBlock(limit:1, filter:{slug:{eq:"team-licence"}}){
        blocks:edges{
          block:node{
            title
            subtitle
            description{
              text:description
            }
            images{
              file{
                url
              }
            }
          }
        }
    }
}
`

export default props => (
    <StaticQuery
      query={query}
      render={data => <LisenceBlock data={data} {...props} />}
    />
)

