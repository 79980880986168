import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Swiper from "swiper"

const Wrapper = styled.div`
  overflow-x: hidden;
  position: relative;
`

const Pagination = styled.div`
  ${props => (props.showMobile ? "" : "display:none;")}
  height: 20px;
  position: relative;
  text-align: center;
  padding-bottom: 6px;
  margin-top: 24px;
  margin-top: 0px;

  @media (min-width: 768px) {
    display: block;
  }
`

const NextArrow = styled.div`
  display: none;

  @media (min-width: 980px) {
    display: flex;
  }

  cursor: pointer;
  background-color: #e01a22;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 124px;
  z-index: 4;
  -webkit-box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  outline: none;

  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c02026;
  }
`

const BackArrow = styled.div`
  display: none;

  @media (min-width: 980px) {
    display: flex;
  }

  cursor: pointer;
  background-color: #e01a22;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 20px;
  top: 124px;
  z-index: 4;
  -webkit-box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.16);
  outline: none;
  align-items: center;
  justify-content: center;

  img {
    transform: scaleX(-1);
  }

  &:hover {
    background-color: #c02026;
  }
`

class Carousel extends React.Component {

  constructor(props) {
    super(props)
    this.swiper = null
  }

  componentDidMount() {
    this.swiper = new Swiper(`.swiper-container-${this.props.hash}`, {
      direction: "horizontal",
      pagination: {
        el: `.swiper-pagination-${this.props.hash}`,
      },
      navigation: {
        nextEl: `.swiper-next-${this.props.hash}`,
        prevEl: `.swiper-prev-${this.props.hash}`,
      },
      a11y: {
        prevSlideMessage: "Previous slide",
        nextSlideMessage: "Next slide",
      },
      autoplay: this.props.autoplay,
      slidesPerView: 1.2,
      spaceBetween: 10,
      breakpoints: this.props.breakpoints,
      loop: this.props.loop,
      initialSlide: this.props.initialSlide,
      on: {
        init: () => {
          setTimeout(() => {
            //document.querySelector(`.swiper-next-${this.props.hash}`).setAttribute('tabindex', '-1')
            //document.querySelector(`.swiper-prev-${this.props.hash}`).setAttribute('tabindex', '-1')
          }, 100)
        }
      }
    })
  }

  render() {
    const {
      children,
      hash,
      pagination
    } = this.props

    return (
      <Wrapper>
        <div className={`swiper-container swiper-container-${hash}`}>
          <div className="swiper-wrapper" role="list">
            {children.map((child, index) => (
              <div key={index} className="swiper-slide" role="listitem">
                {child}
              </div>
            ))}
          </div>
          {pagination && <Pagination>
            <div
              className={`swiper-pagination swiper-pagination-${hash}`}
              style={{
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            ></div>
          </Pagination>}
        </div>
      </Wrapper>
    )
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoints: PropTypes.object.isRequired,
  hash: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  autoplay: PropTypes.any,
  arrows: PropTypes.bool,
  pagination: PropTypes.bool,
  gradients: PropTypes.bool,
}

Carousel.defaultProps = {
  loop: false,
  pagination: true,
  autoplay: {
    delay: 3000,
  },
  initialSlide: 0,
  arrows: true,
  gradients: false,
}

export default Carousel